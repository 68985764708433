<template>
    <div id="content" class="product-category-management">
        <div class="bar-actions">
            <TranslateButton
                :entityId="'tpl_category.' + templateCategoryRender.id"
                :translationLangs="templateCategoryRender.translations"
                @newTranslation="newTranslation"
                @deleteTranslation="deleteTranslation"
            ></TranslateButton>
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button
                bType="save"
                :bCallback="saveTemplateCategory"
                customClass="btn-action"
                :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"
            ></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="showDeteleTemplateCategoryModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container" v-if="templateCategoryRender">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <div class="user-answer">
                            <Input
                                @input="updateValues($event)"
                                v-model="templateCategoryRender.name"
                                :gOptions="{ mandatory: true }"
                                :gPlaceholder="$t('product.product_category.name_placeholder')"
                                :gLabel="$t('user.user_name')"
                                :gMessage="{ error: $t('product.product_category.error_name') }"
                                :gState="errors.name ? 'error' : ''"
                                gSize="m"
                            ></Input>

                            <!-- <Input @input="updateValues($event)" v-model="templateCategoryRender.name" :gOptions="{ mandatory: true }" :gLabel="$t('user.user_name')" :gMessage="{ error: $t('product.product_category.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></Input> -->
                        </div>
                    </div>
                    <div class="right-container translate-disable">
                        <!-- SWITCH HERE PLS -->
                        <div class="switch">
                            <h2 class="label-form" v-html="templateCategoryRender.status ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="switch-click" @click="popupStatus()">
                                <input class="switch-input-form" type="checkbox" v-model="templateCategoryRender.status" disabled="true" />
                            </div>
                        </div>
                        <p class="description" v-html="$t('templates.inactivate')"></p>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container translate-disable">
                        <h2 class="label-form">{{ $t('product.product_category.order') }}</h2>
                        <p class="description" v-html="$t('product.product_category.order_desc')"></p>
                        <p v-if="errors.order" class="error-form" v-html="$t('product.product_category.order_error')"></p>
                        <div class="user-answer">
                            <input
                                type="number"
                                :value="templateCategoryRender.order"
                                @input="
                                    (v) => {
                                        templateCategoryRender.order = v.target.value
                                    }
                                "
                                @change="templateCategoryRender = templateCategoryRender"
                                class="input number"
                                autocomplete="off"
                                name="name"
                            />
                        </div>
                    </div>
                    <div class="right-container translate-disable">
                        <h2 class="label-form">{{ $t('product.product_category.color') }}</h2>
                        <p v-if="errors.color" class="error-form" v-html="$t('product.product_category.color_error')"></p>
                        <div class="user-answer ball-colours">
                            <span
                                class="ball"
                                @click="setColor(col)"
                                v-for="col in colors"
                                :style="{ backgroundColor: col }"
                                :class="{ active: templateCategoryRender.color == col }"
                                :key="col"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'OperationalTimerCategoryForm',
    props: { id: undefined },
    components: {},
    data() {
        return {
            sendForm: true,
            errors: {
                name: false,
                color: false,
                status: false,
                order: false
            },
            idCategory: false,
            form: false,
            colors: extraColors,
            moreOptions: false
        }
    },
    computed: {
        templateCategoryRender: {
            get() {
                if (this.idCategory) {
                    var category = this.$store.getters['operationalTimers/getCategories'](this.idCategory)
                    if (category && category.status == -2) {
                        category.status = 0
                    }
                    return category
                }
            },
            set(value) {
                this.$store.commit('operationalTimers/updateCategory', value)
            }
        },
        optionsType() {
            return [
                {
                    id: 1,
                    name: this.$t('template.categories.type_1')
                },
                // {
                //     id: 2,
                //     name: this.$t('template.categories.type_4')
                // }
                {
                    id: 3,
                    name: this.$t('template.categories.type_3')
                } /* 
                {
                    id: 4,
                    name: this.$t('template.categories.type_2')
                } */
            ]
        }
    },
    methods: {
        newTranslation(code) {
            this.$set(this.templateCategoryRender.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.templateCategoryRender.translations, code)
        },

        // Method for make changes from form to store object
        updateValues(v) {
            // this.templateCategoryRender.name = v
            this.templateCategoryRender = this.templateCategoryRender
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },
        setColor(color) {
            this.templateCategoryRender.color = color
            this.updateValues(color)
        },
        popupStatus() {
            var self = this
            if (self.templateCategoryRender.status == '1' || self.templateCategoryRender.status == true) {
                self.$popup.confirm({
                    message: self.$t('templates.inactivate_popup'),
                    textSave: self.$t('product.product_category.popup_confirm'),
                    callSave: function () {
                        self.templateCategoryRender.status = 0
                        self.updateValues(self.templateCategoryRender.status)
                    },
                    callCancel: function () {
                        self.templateCategoryRender.status = 1
                        self.updateValues(self.templateCategoryRender.status)
                    }
                })
            } else {
                self.templateCategoryRender.status = 1
                self.updateValues(self.templateCategoryRender.status)
            }
        },

        load() {
            var self = this
            // Cargar categorías cuando se hace refresh en el formulario
            if (Object.keys(self.$store.getters['operationalTimers/getCategories']()).length == 0) {
                self.$store.dispatch('operationalTimers/loadCategoriesOperationalTimers', {}).then(() => {
                    if (self.$route.name == 'CategoryOperationalTimers') {
                        if (typeof self.id != 'undefined') {
                            self.$router.push({
                                name: 'EditOperationalTimersCategories',
                                params: { id: self.id }
                            })
                            self.idCategory = self.id
                        } else {
                            self.$store.dispatch('operationalTimers/addNewCategory').then((id) => {
                                self.idCategory = id
                                self.$router.push({ name: 'AddOperationalTimersCategories' })
                            })
                        }
                    } else {
                        if (typeof self.id != 'undefined') {
                            self.idCategory = self.id
                        } else {
                            self.$store.dispatch('operationalTimers/addNewCategory').then((id) => {
                                self.idCategory = id
                            })
                        }
                    }
                })
            } else {
                if (self.$route.name == 'CategoryOperationalTimers') {
                    if (typeof self.id != 'undefined') {
                        self.$router.push({
                            name: 'EditOperationalTimersCategories',
                            params: { id: self.id }
                        })
                        self.idCategory = self.id
                    } else {
                        self.$store.dispatch('operationalTimers/addNewCategory').then((id) => {
                            self.idCategory = id
                            self.$router.push({ name: 'AddOperationalTimersCategories' })
                        })
                    }
                }
            }
        },

        goBack() {
            this.$overlay.show()
            this.$store.dispatch('operationalTimers/cleanCategory').then(() => {
                this.$router.go(-1)
            })
        },
        saveTemplateCategory() {
            var self = this
            self.$overlay.loading()
            this.$store.dispatch('operationalTimers/validateCategory', this.idCategory).then((response) => {
                if (response.status) {
                    var sendForm = self.$route.params.id ? 'editOperationalTimersCategory' : 'addOperationalTimersCategory'
                    self.$store.dispatch('operationalTimers/' + sendForm, self.idCategory).then((response) => {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$route.params.id
                                ? self.$t('snackbar.edit_success') + ' <b>' + self.templateCategoryRender.name + '</b>'
                                : self.$t('snackbar.add_success') + ' <b>' + self.templateCategoryRender.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                } else {
                    self.errors = response.errors
                    self.$overlay.hide()
                    scrollError()
                }
            })
        },

        deleteTemplateCategory() {
            var self = this

            let snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.templateCategoryRender.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('operationalTimers/removeCategory', self.templateCategoryRender.id).then(function (response) {
                self.goBack()
                self.$snackbar.open(snackbarData)
            })
        },

        showDeteleTemplateCategoryModal() {
            var self = this
            const modalMessage = self.$t('product.product_category.popup_delete') + self.templateCategoryRender.name + "'? " + self.$t('templates.popup_delete')

            this.$popup.delete({ message: modalMessage, callSave: this.deleteTemplateCategory })
        }
    },

    created() {
        this.load()
    },
    mounted() {},
    watch: {},
    updated() {}
}
</script>

<style lang="scss">
#content.product-category-management {
    .left-container {
        padding-left: 0;
    }
}
</style>
