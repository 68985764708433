<template>
    <div id="content" class="operationalTimersCategories">
        <template v-if="form">
            <Form :id="operationalTimersCategoryID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <Button
                    :customClass="{ disabled: !hasAccess }"
                    bType="create"
                    :bCallback="addOperationalTimersCategory"
                    bLabel="product.product_category.addButton"
                ></Button>
            </div>

            <div class="operationalTimersCategories-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- sidebar filters -->
                <div
                    class="sidebar-filters"
                    :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }"
                >
                    <FilterCategory
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                        :isDisabled="noChanges && Object.values(items).length == 0"
                        @resetPageDatatable="top(true)"
                    ></FilterCategory>
                </div>
                <!-- table -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        fixed-header
                        :items="Object.values(items)"
                        sort-by
                        :sort-asc="true"
                        hide-default-footer
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                    >
                        <template #item.name="item">
                            <div @click="goToReport(item.item.id)">
                                <span class>{{ item.item.name }}</span>
                            </div>
                        </template>
                        <template #item.color="item">
                            <div @click="goToReport(item.item.id)">
                                <div class="ball" :style="[item.item.color ? { backgroundColor: item.item.color } : { backgroundColor: '#B6B9BE' }]"></div>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div class="status" @click="goToReport(item.item.id)">
                                <Table-statuses
                                    :text="item.item.status == '1' ? $t('sites.form.active') : item.item.status == '-2' ? $t('sites.form.inactive') : ''"
                                    :status="item.item.status == '1' ? 'active' : 'inactive'"
                                >
                                </Table-statuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && Object.values(items).length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>
                    <div id="pagination" v-if="!(noChanges && Object.values(items).length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && Object.values(items).length == 0">
                        <EmptyTable
                            @buttonAction="addOperationalTimersCategory"
                            :buttonText="$t('product.product_category.addButton')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.products_categories')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '../components/ui/TableStatuses.vue'
import FilterCategory from '@/components/domain/operationalTimers/categories/filter'
import Form from '@/components/domain/operationalTimers/categories/form'
import EmptyTable from '@/components/ui/EmptyTable'

export default {
    components: {
        FilterCategory,
        Form,
        EmptyTable,
        TableStatuses
    },
    name: 'OperationalTimersCategories',
    data() {
        return {
            noChanges: false,
            operationalTimersCategoryID: undefined,
            form: false,
            values: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('product.product_category.order'),
                    align: 'start',
                    sortable: true,
                    value: 'order',
                    width: '100px',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.product_category.color'),
                    align: 'start',
                    sortable: true,
                    value: 'color',
                    width: '100px',
                    class: 'header-table'
                },
                {
                    text: this.$t('product.product_category.name'),
                    align: 'start',
                    sortable: true,
                    value: 'name',
                    class: 'header-table'
                },
                /* {
                    text: this.$t('template.categories.type_header'),
                    align: 'start',
                    sortable: true,
                    value: 'type',
                    class: 'header-table'
                }, */

                {
                    text: this.$t('product.product_state.status'),
                    value: 'status',
                    align: 'start',
                    width: '150px',
                    //   sortable: true,
                    //   sort: this.sortColumnGroup,
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            return this.$store.getters['operationalTimers/getCategories']()
        },
        hasAccess() {
            return this.checkPermission('/operationalTimers/categories/add')
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        }
    },
    methods: {
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        goToReport(id) {
            if (this.checkPermission('/operationalTimers/categories/id')) {
                this.form = true
                this.operationalTimersCategoryID = id
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('product.product_category.no_permission'),
                    duration: 5000
                })
            }
        },
        addOperationalTimersCategory() {
            if (this.checkPermission('/operationalTimers/categories/add')) {
                this.form = true
                this.operationalTimersCategoryID = undefined
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('product.product_category.no_permission'),
                    duration: 5000
                })
            }
        },
        closeForm() {
            this.form = false
            this.operationalTimersCategoryID = false
            this.$router.push({
                name: 'OperationalTimersCategories',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        async getCategories() {
            var self = this

            if (Object.keys(self.$store.getters['operationalTimers/getCategories']()).length == 0) {
                self.$store.dispatch('operationalTimers/loadCategoriesOperationalTimers', {}).then(() => {})
            }
        },
        load() {
            if (this.$route.params.id) {
                this.goToReport(this.$route.params.id)
            } else if (this.$route.name == 'AddOperationalTimersCategories') {
                this.addOperationalTimersCategory()
            } else {
                this.form = false
                this.operationalTimersCategoryID = false
            }
            var self = this
            var items = this.$store.getters['operationalTimers/getCategories']()
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('operationalTimersCategoriesFiltersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('operationalTimersCategoriesFiltersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    watch: {
        $route(to, from) {
            // react to route changes...
            // console.log(to, from)
            this.load()
        }
    },
    created() {
        this.getCategories()
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.operationalTimersCategories {
    .operationalTimersCategories-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;

                    &.pending {
                        color: $color-warning-800;
                    }
                }
                .ball {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                }

                .bold {
                    font-family: $text-bold;
                }
                .ball-color {
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    margin-right: 3px;
                }
                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
